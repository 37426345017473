.block_banner {

	@include transition(all 300ms);

	@include media-breakpoint-up(md) {
		.row {
			flex-wrap: nowrap;
			align-items: center;
		}
		.banner_branding {
			width: auto;
			padding-left: 0;
			padding-right: 30px;
		}

		&.sticky {
			@include transition(all 300ms);
			position: fixed;
			z-index: 99999;
			top: 0;
			width: 100%;
			padding: 15px;
			background-color: rgba($body-bg,0.9);
			border-bottom: 2px solid #d3d1c6;
			.logo {
				@include transition(all 300ms);
				display: flex;
				flex-wrap: nowrap;
				width: auto;
				align-items: center;
				@include font-size(36);
				white-space: nowrap;
				small {
					display: inline-block;
					margin-left: 10px;
					padding: 5px 10px 5px 15px;
					@include font-size(15);
				}
			}
		}

	}

}

	.banner_button {}

	.banner_branding {
		text-align: center;
	}
	
	.banner_nav {
		padding-right: 0;
		text-align: right;
	}
