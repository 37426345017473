.banner-nav {

	.nav-item {
		display: inline-block;
		&:not(:last-child) { margin-right: 10px; }
		&.active .nav-link {
			@include font-size(14);
			font-weight: $body-weight-bolder;
			background-color: transparent;
			border-color: transparent;
			color: theme(secondary, dark);
		}
	}

	.nav-link {
		padding: 8px 15px 7px;
		@include font-size(13);
		font-weight: $body-weight-bold;
		text-transform: uppercase;
		border: 1px solid theme(primary, base);
		border-radius: 60px;
		background-color: $body-bg;
		&:hover {
			background-color: theme(primary, base);
			color: $white;
		}
	}

}