.list-listings {

	li {
		@include make-flex;
		flex-wrap: nowrap;
		align-items: center;
		margin: 0 !important;
		padding: 15px;
		@include font-size(14);
		line-height: 1em;
		&:nth-child(even) {
			border: 1px solid darken($body-bg, 7.5%);
			background-color: $white;
		}
	}

	.address {
		width: 65%;
		margin: 0;
	}

		strong {
			display: block;
			margin-bottom: 8px;
			@include font-size(17);
		}

	.contact-wrapper {
		width: 35%;
		margin: 0;
		text-align: right;
	}

		.phone {
			@include font-size(17);
			font-weight: $body-weight-bolder;
			letter-spacing: -0.0375em;
			color: $color-text;
		}

		.category-wrapper {
			display: block;
			margin-top: 5px;
		}

			.category {
				display: inline-block;
				padding: 5px 10px;
				@include font-size(12);
				font-weight: $body-weight-bolder;
				line-height: 1em;
				border-radius: 30px;
				background-color: theme(accent, base);
				color: $white;
			}

  	@include media-breakpoint-up(xl) {
		.address br { display: none; } 		
  	}

}

.list-listings > li.featured {

	border-left: 5px solid theme(primary, light) !important;
	margin-bottom: 10px !important;
	font-weight: $body-weight-bold;
	
	//a { color: theme(secondary, dark); }
	//.phone { color: $color-text; }

	a:not(.phone)::after {
		position: relative;
		display: inline-block;
		content: " \259F";
		vertical-align: top;
		top: -0.04em;
		@include transform(scale(0.5) rotate(-45deg));
	}

	.category { background-color: theme(secondary, base); }

	&:nth-of-type(2n) { border-left-color: theme(primary, dark) !important; }

}