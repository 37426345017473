.block {
	padding: 30px 15px;
}

.block_front {}

	.block_title {}

		.block-title {}

	.block_media {}

	.block_text {}

	.block_more {}