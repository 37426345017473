.phone {
	text-decoration: none;
	color: theme(primary, base);
	&:hover {
		cursor: default;
		color: theme(primary, base);
	}
}

	.contact-call {
		line-height: 1em;
	}

	.banner-call {
		margin-bottom: 0;
	}

	.contact-title {
		@include font-size(18);
		//font-family: $font-family-sans-serif-alt;
		font-weight: $heading-weight-bold;
		letter-spacing: -0.0325em;
		line-height: 1em;
	}

	.contact-subtitle {
		@include font-size(15);
		line-height: 1.35em;
	}
	
	.banner-phone {
		display: block;
		@include font-size(30);
		font-weight: $body-weight-bolder;
		line-height: 1em;
		letter-spacing: -0.0475em;
	}
