.site_info {

	padding: 15px;
	background-color: theme(primary, dark);
	color: $white;

	a { color: $white; text-decoration: none; }
	.list > li:not(:last-child) { margin-bottom: 4px; }

	@include media-breakpoint-up(md) {
		text-align: center;
		.list > li { display: inline-block; margin: 0 15px 0 0 !important;}
	}

}
