.widget_menu {

	.menu_item {
		&:not(:last-child) { border-bottom: 1px dotted rgba($color-text, 0.4); }
		&.active {
			> .menu_link {
				color: theme(primary, base);
				font-weight: $body-weight-bolder;
				.caret-push {
					width: 0;
					margin-right: 0;
				}
			}
		}
	}

	.menu_link {
		padding: 10px 2px;
		@include font-size(14);
		font-weight: $body-weight-bold;
		color: $color-text;

		&:hover,
		&:focus {
			background-color: $white;
			color: theme(accent, base);
			.caret-push {
				@include transition(all ease-in-out 300ms);
				width: 12px;
				margin-right: 3px;
			}			
		}

		.caret-push { fill: theme(accent, base); }

	}

}