.directory-nav {

	.nav-item {
		&:not(:last-child) { border-bottom: 1px solid rgba(0,0,0,0.1); }
		&.active .nav-link {
			font-weight: $body-weight-bolder;
			background-color: transparent;
			color: theme(secondary, base);
		}
	}

	.nav-link {
		@include transition(all ease-in-out 300ms);
		display: block;
		padding: 12px 15px;
		font-weight: $body-weight-bold;
		&:hover {
			color: white;
			background-color: theme(primary, base);
		}
	}

}